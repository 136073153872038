<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="updateDialogAppointment"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title
        class="d-flex pa-4 justify-center dialog-header align-center text-center"
      >
        <span>{{ $t('dashboard.appointmentMissingDialog.title') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="h-overflow">
          <v-list>
            <v-list-item
              v-for="consultation in dialogYouAbsenceStore.consultations"
              :key="consultation.id"
              class="mb-4 border-list"
            >
              <v-card class="pa-4" outlined>
                <v-row no-gutters>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-self-start"
                  >
                    <v-avatar size="80" class="ml-10">
                      <v-img :src="consultation.photo"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.fullName')
                          }}
                          :</strong
                        >
                        {{ consultation.customerName }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.reason')
                          }}
                          :</strong
                        >
                        {{ consultation.appointmentReason }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.duration')
                          }}
                          :</strong
                        >
                        {{ consultation.duration }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.price')
                          }}
                          :</strong
                        >
                        {{ consultation.price }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.date')
                          }}
                          :</strong
                        >
                        {{ consultation.date }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.appointmentMissingDialog.time')
                          }}
                          :</strong
                        >
                        {{ consultation.time }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="consultation.note"
                      :label="$t('dashboard.appointmentMissingDialog.note')"
                      outlined
                      class="w-full mt-2 custom-textarea"
                      rows="2"
                      variant="filled"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="mt-4 text-center">
                      <div class="d-flex justify-center mt-2">
                        <v-btn
                          icon
                          color="green"
                          class="mx-2 text-white"
                          @click="confirmAbsence(consultation.id)"
                        >
                          {{ $t('dashboard.appointmentMissingDialog.confirm') }}
                        </v-btn>
                        <v-btn
                          icon
                          color="red"
                          class="mx-2 text-white"
                          @click="
                            disputeAbsence(consultation.id, consultation.note)
                          "
                        >
                          {{ $t('dashboard.appointmentMissingDialog.dispute') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-col cols="12">
        <span class="mr-auto dialog-text">
          {{ $t('dashboard.appointmentMissingDialog.modifyChoiceInfo') }}
        </span>
      </v-col>
      <v-card-actions class="justify-end dialog-actions">
        <v-btn class="text-white btn--save" @click="validate">
          {{ $t('dashboard.appointmentMissingDialog.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import createHttpClient from '@/api/httpClient'
import { useDialogYourAbsenceStore } from '@/store/dialogYourAbsenceStore/dialogYourAbsenceStore'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import MessageService from '@/components/feedback/message/messageService'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'

export default {
  props: {
    dialog: Boolean,
  },
  emits: ['update:dialog'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()

    const dialogYouAbsenceStore = useDialogYourAbsenceStore()
    const consultations = ref([
      // {
      //   id: 1,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])
    const updateDialogAppointment = value => {
      emit('update:dialog', value)
    }

    const toggleDialog = () => {
      updateDialogAppointment(false)
    }

    const validate = () => {
      updateDialogAppointment(false)
    }

    const confirmAbsence = async id => {
      // Handle confirm confirmAbsence
      LoadingBackdropService.start()
      try {
        await httpClient.post(`/opinion/absence-confirm/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      await dialogYouAbsenceStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const disputeAbsence = async (id, commentPatient) => {
      // Handle dispute absence
      LoadingBackdropService.start()
      try {
        await httpClient.post(`/opinion/absence-protest/${id}`, {
          commentPatient,
        })
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      await dialogYouAbsenceStore.fetchConsultations()

      LoadingBackdropService.stop()
    }

    return {
      t,
      consultations,
      dialogYouAbsenceStore,
      updateDialogAppointment,
      toggleDialog,
      validate,
      confirmAbsence,
      disputeAbsence,
    }
  },
}
</script>

<style scoped>
.v-btn {
  min-width: 120px;
  border-radius: 60px !important;
}

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

.border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.dialog-header {
  background-color: #fef4eb;
  color: #f09954;
  text-align: center;
}

.h-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  color: gray;
  font-size: 13px;
  text-align: justify;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}
</style>
